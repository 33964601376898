<template>
  <div class="sign-page">
    <person-head></person-head>
    <div class="sign-main">
      <div class="personage_head">
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li
              @click="changeType('fang_types',item.value)"
              :class="{ active: from.fang_types == item.value }"
              v-for="(item, index) in getZuHousingTypeList()"
              :key="index"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <!-- <div class="personage_head_top">
          <div class="personage_head_txt">租售类型:</div>
          <ul class="personage_head_ul">
            <li @click="changeType(1, 2)" :class="{ active: from.fang_type == 1 }">出售</li>
            <li @click="changeType(2, 2)" :class="{ active: from.fang_type == 2 }" v-if="from.fang_types != 1">出租</li>
          </ul>
        </div> -->
      </div>
      <!--rate_page_list start-->
      <div class="rate_page_list">
        <ul class="personage_ul">
          <!--li start-->
          <li @click="hrefDetail(item)" v-for="(item,index) in list" :key="index">
            <div class="personage_ul_img m-r-20">
              <img
                :src="item.fengmian_image"
                alt=""
              />
              <div class="personage_img_marking">{{ item.fang_level }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="personage_ul_name">{{ item.name }}</div>
              <!-- <div class="personage_ul_title">
                合同期限：2020-10-10至2021-10-10
              </div> -->
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{ item.price || 0 }}元</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-between">
                <div class="tip color_orange" :class="{color_blue:item.status == 1 || item.status == 3}">{{ getStatus(item.status) }}</div>
                <div class="personage_ul-bot" v-if="item.status != 2">
                  <button @click="changeItem(0,item)" class="rate_page_btn red m-r-20" v-if="item.status == 0">终止合作</button>
                  <button   class="rate_page_btn blue" v-if="item.status == 0">上传信息</button>
                   <button @click.stop="changeItem(1,item)" class="rate_page_btn blue" v-if="item.status == 1">成交报告</button>
                   <button @click="changeItem(2,item)" class="rate_page_btn" v-if="item.status == 3">查看详情</button>
                </div>
              </div>
            </div>
          </li>
          <!--li end-->
        </ul>
      </div>
      <!--rate_page_list end-->
      <div class="paging" v-if="list.length">
        <el-pagination
          background
           @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <sign-pop ref="report"></sign-pop>
  </div>
</template>

<script>
import PersonHead from "../../components/PersonHead";
import signPop from "../../components/SignPop.vue"
import {mapGetters} from "vuex";
export default {
  components: { PersonHead,signPop },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      from: {
        fang_types: 2, //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        fang_type: 2, //房源类型：1=出售2=出租(新房默认1
        page: 1, //页数
      },
    };
  },
  methods: {
    //
    changeItem (index,item) {
      if (index == 0) {

      } else if (index == 1) {
        this.$refs.report.open(item.id,item.fang_type,item.fang_types)
      } else if (index == 2) {
        
      }
    },
     //分页
    changePage (e) {
      this.from.page = e
       this.getSignAContract();
    },
    //切换类型
    changeType(type,value) {
      if (this.from[type] == value) return
      this.from[type] = value
      this.init();
    },
    init() {
      this.list = [];
      this.from.page = 1;
      this.getSignAContract();
    },
    //跳转页面
    hrefDetail(item) {
      this.$router.push({ path: "/personage/offSignDetails",query:{id:item.id,fang_type:item.fang_type,fang_types:item.fang_types} });
    },
    //获取列表
    getSignAContract() {
      this.$axios.offlineContract(this.from).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    getStatus (status) {
      let txt = ''
      switch (status) {
        case 0:
          txt = '客户确认中'
          break;
        case 1:
          txt = '签约完成'
          break;
        case 2:
          txt = '交易失败'
          break;
        case 3:
          txt = '已完成'
          break;
        default:
          break;
      }
      return txt
    }
  },
  created() {
    if ((this.userInfo.position === 3 || this.userInfo.position === 4) && this.userInfo.fang_type_privilege === 1) this.from.fang_types = 4
    this.getSignAContract();
  }
};
</script>

<style scoped lang="less">
.personage_head {
  padding: 30px 30px;
  border-bottom: 1px solid #ebebeb;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.sign-main {
  background: #fff;
  padding: 0 20px;
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}
.personage_ul {
  padding: 0 30px;
  li {
    padding: 30px 0px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .tip {
      font-size: 14px;
    }
    .color_orange {
      color: #FF9600;
    }
    .color_blue {
      color: #3273F6;
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
